import React from 'react';
import { Container } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import logo from '../logo.png';

import './Tdsbanner.css';
class Tdsbanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: {
                    nav: false,
                    dots: true,
                },
                767: {
                    nav: true
                },

            },
        }

    }

    render() {
        return (

            <section className="banner">

                <OwlCarousel
                    className="banner-owl owl-theme"
                    loop={false}
                    margin={0}
                    autoplay={true}
                    autoplayTimeout={3000}
                    smartSpeed={500}
                    nav={true}
                    items="1"
                    responsive={this.state.responsive}
                    dots={false} >
                    <div className="item">
                        <div className="banner-top-panel">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="banner-one banner-img">
                            <Container>
                                <div className="banner-text text-center">
                                    <h1>Learning <span>skills</span> and new <span>behaviors</span> for life</h1>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> */}
                                    {/* TODO: Add appointment button */}
                                    {/* <div className="d-flex justify-content-center">
                                        <Button color="primary mr-3">Request an appointment</Button>
                                    </div> */}
                                </div>
                            </Container>
                        </div>
                    </div>
                </OwlCarousel>
            </section>
        );
    }
}


export default Tdsbanner;
