/**
 * Entry application component used to compose providers and render Routes.
 * */
import React from 'react';
import {
    Button, Container, Row, Col, Media, Form, FormGroup, Input, ListGroup, ListGroupItem
} from 'reactstrap';
// Web Icon fonts
import Icofont from 'react-icofont';
// Web theme color root css
import './components/colors.css';
import Tdsheader from './components/Tdsheader';
import Tdsbanner from './components/Tdsbanner';
import Tdsfooter from './components/Tdsfooter';
import LocationsComponent from './components/LocationsComponent';

import './App.css';
import './responsive.css';
class App extends React.Component {

    render() {
        return (
            <div className="App" >
                {/* Navbar  */}
                <Tdsheader />

                {/* Hero Section Start */}
                <div id="home" className="section">
                    <Tdsbanner />
                </div>
                {/* Welcome Section Start */}
                <div className="section welcome-box grapping" id="about">
                    <Container>
                        <div className="title-box">
                            <h2>Welcome to <b>Angel Behavioral Path</b></h2>
                            <p>ABP is an Applied Behavior Analysis provider, aiming to expand ABA services to more children, focused on a comprehensive approach to support the families served</p>
                        </div>
                        <Row>
                            <Col xs="12" sm="12" md="6">
                                <div className="white-bg wel-box coman-box">
                                    <Icofont icon="icofont-bullseye" />
                                    <h4>Mission</h4>
                                    <p>ABP is committed to provide Applied Behavior Analysis (ABA) services to children and their families, teachers, and other specialists/service providers to help and support them in a collaborative relationship, where the children achieve the most beneficial outcome, by decreasing challenging behaviors and learning independent skills, through the implementation of evidence-based strategies</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="6">
                                <div className="white-bg wel-box coman-box">
                                    <Icofont icon="icofont-telescope" />
                                    <h4>Vision</h4>
                                    <p>ABP aims to deliver ABA services to children, their families and other providers to decrease problem behaviors that interfere in the daily routines, while teaching replacement and appropriate behaviors</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Our Services Section Start */}
                <div className="section ourservice grapping" id="services">
                    <Container>
                        <div className="title-box">
                            <h2>Our  <b>Services</b></h2>
                            <p>We serve children up to 10 years old with different diagnoses including Autism. Behavior analysts coordinate with the families to decide what modality should be used in the delivery of the services, according to the kids’ needs. We speak English and Spanish while conducting the initial assessment process and during the parent trainings meetings</p>
                        </div>
                        <Row>
                            <Col xs="12" sm="6" md="6" lg={{ size: 4, offset: 2 }}>
                                <div className="white-bg coman-box ourservice-box">
                                    <Icofont icon="icofont-user-male" />
                                    <h4>Via In-Person</h4>
                                    <p>Sessions are delivered by the therapist on a 1:1 modality, the client is face-to-face with our staff. This modality may be used at different settings</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="4">
                                <div className="white-bg coman-box ourservice-box">
                                    <Icofont icon="icofont-laptop-alt" />
                                    <h4>Via Telehealth</h4>
                                    <p>Sessions are delivered using an online HIPPA compliant platform. It is used mostly at home setting</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="6" md="6" lg={{ size: 3, offset: 1 }}>
                                <div className="white-bg coman-box ourservice-box">
                                    <Icofont icon="icofont-home" />
                                    <h4>At Home</h4>
                                    <p>Therapy can be provided at home via In-Person or via Telehealth. Independent skills are promoted during the daily routines</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="4">
                                <div className="white-bg coman-box ourservice-box">
                                    <Icofont icon="icofont-book" />
                                    <h4>At School</h4>
                                    <p>Services are provided to help and support students to accomplish the assigned tasks by reducing challenging behaviors and learning new skills</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3">
                                <div className="white-bg coman-box ourservice-box">
                                    <Icofont icon="icofont-people" />
                                    <h4>At Community</h4>
                                    <p>Services are provided during outings to foster social skills development in the relationship with peers and adults <em>(On hold due to local COVID-19 restrictions)</em></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section grapping locations" id="locations">
                    <div className="title-box">
                        <h2>Our <b>Locations</b></h2>
                        <p>ABP members provide Applied Behavior Analysis services throughout the urban areas of the San Francisco Bay Area including Santa Clara and San Mateo counties</p>
                    </div>
                    <LocationsComponent />
                </div>
                {/* Team Section Start */}
                <div className="section expert-team grapping" id="team">
                    <Container>
                        <div className="title-box">
                            <h2>Our <b>Team</b></h2>
                            <p>ABP team is integrated by qualified staff that have received trainings in Applied Behavior Analysis. Behavior Therapists deliver 1:1 therapy and Lead Analysts provide on-going supervision to the therapists, as well as parent trainings to the families we served. Behavior Technicians receive supervision systematically in order to provide services with quality and according to the Board Analyst Certification Board (BACB).</p>
                        </div>
                        <Row>
                            <Col xs="12" sm="6" md="6" lg={{ size: 4, offset: 1 }}>
                                <div className="text-center coman-box team-box">
                                    <div className="team-img">
                                        <img src={require("./images/team-img5.jpg")} alt="" className="img-fluid rounded-circle" />
                                    </div>
                                    <h4 className="text-white">Elsa Rodriguez</h4>
                                    <p className="team-member-title">M.A. ABA, BCBA - Founder</p>
                                    <p className="text-white">Elsa has been working with children with Autism, Developmental Disabilities and other diagnoses since 2015. She has delivered ABA services to children of different ages as an RBT, BCaBA and BCBA. Elsa Rodriguez has provided support to parents, caregivers, teachers and other specialists, in English and Spanish, in the states of Florida and California.</p>
                                    <ListGroup className="social-icon-team">
                                        <ListGroupItem tag="a" target="_blank" href="https://www.linkedin.com/in/elsa-rodriguez-pasto-88ba85165/">  <Icofont icon="icofont-linkedin" /></ListGroupItem>
                                    </ListGroup>
                                </div>

                            </Col>
                            <Col xs="12" sm="6" md="6" lg="6">
                                <div className="white-bg wel-box coman-box hiring">
                                    <h4>We are currently hiring</h4>
                                    <p>ABP is hiring full and part-time positions for <b>Behavior Therapists</b> that are willing to obtain the RBT certification, as well as <b>Registered Behavior Technicians</b> that love to work with children in a daily basis.</p>
                                    <p>We are also recruiting <b>Behavior Analysts</b> that can provide supervision to our staff and support/training to our families.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section grapping partners" id="partners">
                    <Container>
                        <div className="title-box">
                            <h2>Our <b>Insurance Partners</b></h2>
                            <p>ABP is currently credentialed and contracted with the following funding sources:</p>
                        </div>
                        <Row>
                            <Col xs="6" sm="4" md="4" lg="4" className="mt-4">
                                <img src={require("./images/aetna.png")} alt="" className="img-fluid" />
                            </Col>
                            <Col xs="6" sm="4" md="4" lg="4" className="mt-4">
                                <img src={require("./images/ubh.png")} alt="" className="img-fluid" />
                            </Col>
                            <Col xs="6" sm="4" md="4" lg="4" className="mt-4">
                                <img src={require("./images/magellan.png")} alt="" className="img-fluid" />
                            </Col>
                            <Col xs="6" sm="4" md="4" lg="4" className="mt-4">
		{/* <Col xs="6" sm={{size:4, offset:2}} md={{size:4, offset:2}} lg={{size:4, offset:2}} className="mt-4"> */}
                                <img src={require("./images/blueshieldca.png")} alt="" className="img-fluid" />
                            </Col>
                            <Col xs="6" sm="4" md="4" lg="4" className="mt-4">
                                <img src={require("./images/ggrc.png")} alt="" className="img-fluid" />
                            </Col>
                            <Col xs="6" sm="4" md="4" lg="4" className="mt-4">
                                <img src={require("./images/kaiser.png")} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Contact Us Section Start  */}
                <div className="section contactus grapping" id="contact">
                    <Container>
                        <div className="title-box">
                            <h2>Contact   <b>Us</b></h2>
                            <p>Please contact us to make a consultation, evaluation or to ask any questions regarding the services we provide</p>
                        </div>
                        <Row>
                            <Col xs="12" md="6" lg="4">
                                <Media className="address-box">
                                    <Media left href="mailto:contact@abehavioralpath.com" >
                                        <Icofont icon="icofont-mail" />
                                    </Media>
                                    <Media body>
                                        <Media heading>
                                            Email
                                        </Media>
                                        <a href="mailto:contact@abehavioralpath.com">contact@abehavioralpath.com</a> <br />
                                    </Media>
                                </Media>
                            </Col>
                            <Col xs="12" md="6" lg="4">
                                <Media className="address-box">
                                    <Media left href="tel:(408) 622 9222" >
                                        <Icofont icon="icofont-phone" />
                                    </Media>
                                    <Media body>
                                        <Media heading>
                                            Phone

                                        </Media>
                                        <a href="tel:(408) 622 9222"> (408) 622 9222</a> <br />
                                    </Media>
                                </Media>
                            </Col>
                            <Col xs="12" md="6" lg="4">
                                <Media className="address-box">
                                    <Media left href="fax:(669) 202 3173">
                                        <Icofont icon="icofont-fax" />
                                    </Media>
                                    <Media body>
                                        <Media heading>
                                            Fax

                                        </Media>
                                        <a href="fax:(669) 202 3173"> (669) 202 3173</a> <br />
                                    </Media>
                                </Media>
                            </Col>
                        </Row>
                        <Row className="mt-3 contact-form">
                            <Col xs="12" md="12" lg="4">
                                <h4>Get in Touch</h4>
                                <hr />
                                <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete. account of the system, and expound the actual teachings of the great explorer praising  I will give you a complete </p>
                                <ListGroup className="social-icon">
                                    <ListGroupItem tag="a" href="#">  <Icofont icon="icofont-facebook" /></ListGroupItem>
                                    <ListGroupItem tag="a" href="#">  <Icofont icon="icofont-twitter" /></ListGroupItem>
                                    <ListGroupItem tag="a" href="#">  <Icofont icon="icofont-instagram" /></ListGroupItem>
                                    <ListGroupItem tag="a" href="#">  <Icofont icon="icofont-linkedin" /></ListGroupItem>
                                    <ListGroupItem tag="a" href="#">  <Icofont icon="icofont-youtube-play" /></ListGroupItem>
                                </ListGroup>
                            </Col>

                            <Col xs="12" md="12" lg="8">
                                <Form className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>

                                                <Input type="text" name="name" id="examplename" placeholder="Name" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>

                                                <Input type="email" name="email" id="exampleEmail" placeholder="Email" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>

                                        <Input type="textarea" name="address" id="exampleAddress" placeholder="Your message" />
                                    </FormGroup>



                                    <Button color="primary">Send Message</Button>
                                </Form>
                            </Col>
                        </Row>

                    </Container>
                </div>
                {/* Footer  Section Start */}
                <div className="">
                    <Tdsfooter></Tdsfooter>
                </div>
            </div>
        );
    }
}

export default App;

