import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import logo from '../logoclear.png';

import './Tdsfooter.css';


class Tdsfooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (

            <footer>
                <Container>
                    <Row className="align-items-center">
                        <Col xs="12" sm="12" md="12" lg="12">
                            <img src={logo} width="83" alt="logo" />
                        </Col>
                        <p>Copyright @ 2021 Angel Behavioral Path LLC., All rights reserved</p>
                    </Row>
                </Container>

            </footer>
       );
    }
}

export default Tdsfooter;
