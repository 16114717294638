// @flow 
import * as React from 'react';
import { Row, Col, Media } from "reactstrap";
import Icofont from 'react-icofont';

import "./LocationComponent.css";
import $ from "jquery";
import "jquery-mapael";
import "jquery-mapael/js/maps/california.js";

type CountyCities = { label: string, locs: string[] };

const locations: { [county_id: string]: CountyCities } = {
    "San_Mateo": {
        label: "San Mateo County",
        locs: [
            "San Mateo", "Redwood City", "Menlo Park", "East Palo Alto", "West Menlo Park", "San Bruno", "Burlingame", "San Carlos", "Daly City"
        ],
    },
    "Santa_Clara": {
        label: "Santa Clara County",
        locs: [
            "Santa Clara", "San Jose", "Sunnyale", "Palo Alto", "Cupertino", "Milpitas", "Mountain View", "Morgan Hill", "Los Gatos", "Campbell", "Saratoga", "Los Altos", "Stanford"
        ],
    },
    "San_Francisco": {
        label: "San Francisco",
        locs: [
            "San Francisco"
        ],
    }
};

function LocationsComponent(props: {}): React.Node {
    var el: HTMLDivElement | null;
    var countyListElems: { [county: string]: null | HTMLUListElement } = {};

    React.useEffect(() => {
        var $el = $(el);
        $el.mapael({
            map: {
                name: "california",
                defaultArea: {
                    attrs: {
                        stroke: "#fff",
                        "stroke-width": 1,
                        fill: "#4A9FA5",
                    },
                    attrsHover: {
                        "stroke-width": 2,
                        fill: "#999999",
                    },
                },
            },
            areas: {
                Santa_Clara: {
                    attrs: {
                        fill: "#f6994b",
                    },
                    tooltip: { content: "Santa Clara County" },
                    eventHandlers: {
                        mouseover: OnMapHover,
                        mouseout: OnMapHoverEnd
                    }
                },
                San_Mateo: {
                    attrs: {
                        fill: "#f6994b",
                    },
                    attrsHover: {
                        fill: "#999999",
                    },
                    tooltip: { content: "San Mateo County" },
                    eventHandlers: {
                        mouseover: OnMapHover,
                        mouseout: OnMapHoverEnd
                    }
                },
                San_Francisco: {
                    attrs: {
                        fill: "#f6994b",
                    },
                    attrsHover: {
                        fill: "#999999",
                    },
                    tooltip: { content: "San Francisco" },
                    eventHandlers: {
                        mouseover: OnMapHover,
                        mouseout: OnMapHoverEnd
                    }
                },                
            },
        });
    });

    var OnMapHover = (e, id, mapElem, textElem) => {
        /**
        * To trigger map update do:
        * $(".mapcontainer").trigger('update', [{mapOptions: newData}]);
        */
        const kElem = countyListElems[id];
        if (kElem !== null && !kElem.classList.contains("active")) {
            kElem.classList.add("active");
        }
    }

    var OnMapHoverEnd = (e, id, mapElem, textElem) => {
        const kElem = countyListElems[id];
        if (kElem !== null && kElem.classList.contains("active")) {
            kElem.classList.remove("active");
        }
    }

    return (
        <div ref={(e) => (el = e)}>
            <Row>
                <Col xs="12" sm="12" md="4" lg="4">
                    <div className="map">Placeholder</div>
                </Col>
                <Col className="city-list" xs="12" sm="12" md="6" lg="6">
                    {Object.keys(locations).map((county_id, key, a) => {
                        return (
                            <div key={key}>
                                <h4 className="sub-title">{locations[county_id].label}</h4>
                                <ul ref={(e) => countyListElems[county_id] = e}>
                                    {locations[county_id].locs.map((city, idx, a) => {
                                        return (
                                            <li key={idx}>
                                                <Media>
                                                    <Media left>
                                                        <Icofont icon="icofont-location-pin" />
                                                    </Media>
                                                    <Media body>
                                                        {city}
                                                    </Media>
                                                </Media>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </Col>
            </Row >
        </div >
    );
}

export default LocationsComponent;
